
import PlataformaService from "../../../Services/PlataformaService";
export default {
  name: "pltaforma-gestor",
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Configuración" }, { etiqueta: "Plataformas" }],
      statusFiltroOpciones: [
        { value: 200, label: "Activo" },
        { value: 301, label: "Archivado" },
        { value: 300, label: "Eliminado" },
      ],
      statusOpciones: [
        { value: 200, label: "Activo" },
        { value: 301, label: "Archivado" },
      ],
      filtros: {
        busqueda: "",
        status: [],
      },
      plataformas: [],
      columnas: [
        {
          name: "nombre",
          header: "Plataforma",
          align: "left",
          field: "titulo",
          minWidth: 160,
          width: 250,
        },
        {
          name: "fechaInicio",
          header: "Fecha de inicio",
          align: "left",
          field: "fecha_inicio",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 130,
          width: 130,
          formatear: (val) => this.formatearFecha(val, 2),
        },
        {
          name: "fechaFin",
          header: "Fecha fin",
          align: "left",
          field: "fecha_fin",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 100,
          width: 120,
          formatear: (val) => this.formatearFecha(val, 2),
        },
        {
          name: "status",
          header: "Status",
          align: "left",
          field: "status",
          minWidth: 100,
          width: 120,
          templateName: "status",
          statusName: "status_nombre",
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 100,
          width: 120,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
        {
          name: "registroAutor",
          header: "Autor",
          align: "left",
          field: "registro_autor",
          minWidth: 140,
          width: 200,
        },
        {
          name: "opciones",
          header: "",
          align: "center",
          minWidth: 50,
          width: 50,
          columnChooser: false,
          templateName: "opciones",
          templateRestriccionCampo: "status",
          templateRestriccionValores: [300],
          templateOpciones: [
            { accion: "editar", icono: "editar" },
            { accion: "eliminar", icono: "eliminar" },
          ],
        },
      ],
      accionLimpiar: false,
      modalAgregar: false,
      agregarPlataformaObj: {
        titulo: "",
        fechaInicio: "",
        fechaFin: "",
      },
      modalEditar: false,
      modalEliminar: false,
      editarPlataformaObj: {},
    };
  },
  mounted() {
    this.cargaInicial();
  },
  watch: {
    "agregarPlataformaObj.fechaInicio"(val) {
      if (val == "") this.agregarPlataformaObj.fechaFin = "";
    },
  },
  computed: {
    fechaInicioCapturada() {
      return this.agregarPlataformaObj.fechaInicio != "";
    },
    fechaInicioCapturadaEdicion() {
      return this.editarPlataformaObj.fecha_inicio != "";
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerPlataformas(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        status: this.obtenerArrayParametrosQuery(query.s, true), // El segundo parametro es para arrays con valores numericos
        ordenar: "titulo_asc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.status,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerPlataformas(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await PlataformaService.listarGestor(this.filtros)
        .then((resp) => {
          let plataformas = resp;
          this.plataformas = plataformas.map((p) => {
            return {
              ...p,
              registro_fecha: new Date(p.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarPlataforma(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ...this.agregarPlataformaObj,
        fechaInicio: this.dayjs(
          this.agregarPlataformaObj.fechaInicio,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };

      if (this.agregarPlataformaObj.fechaFin != "") {
        datos.fechaFin = this.dayjs(
          this.agregarPlataformaObj.fechaFin,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
      await PlataformaService.agregar(datos)
        .then(async (resp) => {
          this.cerrarModalAgregar();
          await this.obtenerPlataformas(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarPlataforma(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        plataformaId: this.editarPlataformaObj.plataforma_id,
        titulo: this.editarPlataformaObj.titulo,
        fechaInicio: this.dayjs(
          this.editarPlataformaObj.fecha_inicio,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        fechaFin: "",
        status: this.editarPlataformaObj.status,
      };

      if (this.editarPlataformaObj.fecha_fin) {
        datos.fechaFin = this.dayjs(
          this.editarPlataformaObj.fecha_fin,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
      await PlataformaService.editar(datos)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerPlataformas(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async eliminarPlataforma(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        plataformaId: this.editarPlataformaObj.plataforma_id,
      };
      await PlataformaService.eliminar(datos)
        .then(async (resp) => {
          this.cerrarModalEliminar();
          await this.obtenerPlataformas(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        status: [],
        ordenar: "titulo_asc",
      };
      this.obtenerPlataformas();
      this.accionLimpiar = true;
    },
    manejarClickOpcion(plataforma, accion) {
      if (accion == "editar") this.abrirModalEditar(plataforma);
      if (accion == "eliminar") this.abrirModalEliminar(plataforma);
    },
    abrirModalAgregar() {
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.agregarPlataformaObj = {
        titulo: "",
        fechaInicio: "",
        fechaFin: "",
      };
      this.modalAgregar = false;
    },
    abrirModalEditar(plataforma) {
      this.editarPlataformaObj = { ...plataforma };
      // Se formatean fechas para edicion
      let fechaInicio = this.dayjs(
        this.editarPlataformaObj.fecha_inicio
      ).format("YYYY-MM-DD");
      let fechaActual = this.dayjs().format("YYYY-MM-DD");
      if (this.dayjs(fechaInicio).isBefore(fechaActual)) {
        this.editarPlataformaObj.fecha_inicio_original = fechaInicio;
      } else this.editarPlataformaObj.fecha_inicio_original = fechaActual;

      this.editarPlataformaObj.fecha_inicio = this.dayjs(
        this.editarPlataformaObj.fecha_inicio
      ).format("DD/MM/YYYY");

      if (this.editarPlataformaObj.fecha_fin) {
        this.editarPlataformaObj.fecha_fin = this.dayjs(
          this.editarPlataformaObj.fecha_fin
        ).format("DD/MM/YYYY");
      }
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarPlataformaObj = {};
      this.modalEditar = false;
    },
    abrirModalEliminar(plataforma) {
      this.editarPlataformaObj = plataforma;
      this.modalEliminar = true;
    },
    cerrarModalEliminar() {
      this.editarPlataformaObj = {};
      this.modalEliminar = false;
    },
    validarFechaFin(val, fechaInicio) {
      if (this._.isEmpty(val)) return true;
      return this.validarFecha(
        val,
        1,
        false,
        false,
        true,
        2019,
        this.dayjs(fechaInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
      );
    },
  },
};
